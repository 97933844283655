import { LANGUAGES } from "../lang";
import _ from "lodash";

// 5. systemLanguage  -> UI Language of Windows
const browserLanguagePropertyKeys = ['languages', 'language', 'browserLanguage', 'userLanguage', 'systemLanguage'];

const availableLanguages = Object.keys(LANGUAGES);

const detectedLocale = _.chain(window.navigator)
    .pick(browserLanguagePropertyKeys) //Get only language properties
    .values() //Get values of the properties
    .flatten() //flatten all arrays
    .compact() //Remove undefined values
    .map(function (x:string) {
        return x.substr(0, 2); //Shorten strings to use two chars (en-US -> en)
    })
    .find((x)=>{
        return _.includes(availableLanguages, x as unknown as string); //Returns first language matched in available languages
    })
    .value() as unknown as keyof typeof LANGUAGES;

export const LOCALE:keyof typeof LANGUAGES = detectedLocale || 'en'; //If no locale is detected, fallback to 'en'