import * as Redux from "redux";
import Immutable from "immutable";
import { rootReducer } from "./reducer";

// typescript declaration for redux devtools
declare global {interface Window { __REDUX_DEVTOOLS_EXTENSION__: ()=>any }}

/**
 * Creates store
 * @param defaultState
 */
export function createStore(defaultState = Immutable.Map()):Redux.Store {
    return Redux.createStore(
        rootReducer,
        defaultState,
        // Enable redux chrome debug tool
        process.env.NODE_ENV !== "production" ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() : undefined
    );
}
