import React from "react";
import styled from "styled-components";

type Props = {
    showProjects?:boolean;
}
export const CornerButton = styled.div`
    position:fixed;
    top:0;
    background-color:rgba(255,255,255,.5);
    width:calc( 100vw /30 );
    height:calc( 100vw /30 );
    display:flex;
    justify-content:center;
    align-items:center;
    box-shadow:0 0 15px 0 rgba(0,0,0,.15);
    right:0;
    cursor:pointer;
    z-index:1;
    &:hover{
        background-color:rgba(255,255,255,.8);
    }
    transition:transform .1s ease-out, border-radius .1s ease-out;
    ${({showProjects}:React.PropsWithChildren<Partial<Props>>)=>{
        if(showProjects){
            return `
                transition:transform .3s 1s ease-out, box-shadow .1s ease-in, border-radius .3s 1s ease-out;
                transform:scale(.74);
                &:hover {
                    box-shadow:0 0 25px 0 rgba(0,0,0,.25);
                }
            `
        }
    }}
    @media (max-width:1850px) {
        width:calc( 100vw /15 );
        height:calc( 100vw /15 );
    }
    @media (max-width:1050px) {
        width:calc( 100vw /10 );
        height:calc( 100vw /10 );
    }
    @media (max-width:480px) {
        width:calc( 100vw /7.5 );
        height:calc( 100vw /7.5 );
    }
    & img {
        width:100%;
        height:100%;
        object-fit:contain;
        object-position:center;
        transform:scale(.7);
        opacity:.5;
        &:hover {
            opacity:.8;
        }
    }
`;