import { Map } from "immutable";
import React, { FunctionComponent, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import { toggleMenu, toggleProjects } from "../actions/PageActions";
import { PAGE } from "../reducers/PageReducers";
import { SERVER } from "../settings/connection";
import { ServerData } from "../types/data.type";
import { ProjectSelector } from "./ProjectSelector";
import logo from "../assets/logo.gif";
import menuLogo from "../assets/menu.png";
import eye from "../assets/eye.svg";
import { Swipeable } from "react-swipeable";
import { ProjectPage } from "./Project";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import find from "lodash/find";
import { CornerButton } from "./CornerButton";
import { Languages } from "./Languages";
import { Menu } from "./Menu";
import sortBy from "lodash/sortBy";

type ReduxProps = {
    showMenu:boolean;
    showProjects:boolean;
}

type Props = {
    data:ServerData;
} & ReduxProps;

const MenuWrapper = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    position:fixed;
    top:0;
    left:0;
    width:350px;
    max-width:100%;
    height:100vh;
    height:-webkit-fill-available;
    overflow-y:auto;
    overflow-x:hidden;
    background-color:white;
    box-shadow:0 0 15px 0 rgba(0,0,0,.15);
    transform:translateX(-100%);
    transition: 0.2s;
    will-change: transform;
    &.show {
        transform:translateX(0);
    }
`;

const Projects = styled.div`
    @keyframes jump {
        0%   {transform: translate3d(0,0,0);}
        40%  {transform: translate3d(15px,0,0);}
        50%  {transform: translate3d(0,0,0);}
        65%  {transform: translate3d(10px,0,0);}
        75%  {transform: translate3d(0,0,0);}
        90%  {transform: translate3d(5px,0,0);}
        100% {transform: translate3d(0,0,0);}
    }
    position:absolute;
    top:0;
    left:0;
    min-height:100%;
    width:100%;
    background: white;
    transform:translateX(0);
    transition: 0.2s;
    will-change: transform;
    @media (max-width:1050px) {
        animation: jump 1s 1s linear alternate;
    }
`;

const Logo = styled.div`
    position:relative;
    width:calc( 100% /6 );
    height:calc( 100% /6 );
    float:left;
    overflow:hidden;
    user-select:none;
    cursor:pointer;
    padding-top:calc( 100% /6 );

    @media (max-width:1050px) {
        width:calc( 100% /4 );
        height:calc( 100% /4 );
        padding-top:calc( 100% /4 );
    }
    @media (max-width:480px) {
        width:calc( 100% /2 );
        height:calc( 100% /2 );
        padding-top:calc( 100% /2 );
    }

    & img {
        position:absolute;
        width:100%;
        height:100%;
        top:0;
        left:0;
        object-fit: contain;
        transform: scale(0.7);
    }
`;



const PageBase:FunctionComponent<Props> = ({showMenu,showProjects,data})=>{
    const dispatch = useDispatch();
    const ref = useRef<HTMLDivElement>(null);
    const projects = sortBy(data.projects,"time").reverse();
    const [menu,setMenu] = useState(logo);

    return <BrowserRouter><Swipeable style={{position:"absolute",width:"100%",minHeight:"100%",overflowX:"hidden"}} onSwipedRight={()=>dispatch(toggleMenu(true))} onSwipedLeft={()=>dispatch(toggleMenu(false))}>
        <Projects
            style={{
                transform: ref.current && showMenu ? `translateX(${ref.current.getBoundingClientRect().width}px)`:undefined,
            }}>
            <Logo
                onClick={()=>{dispatch(toggleMenu())}}
                onMouseEnter={()=>{setMenu(menuLogo)}}
                onMouseLeave={()=>{setMenu(logo)}}>
                <img src={menu} alt="plan-b architekti"/>
            </Logo>
            <div>
                {projects.map((project)=>{
                    return <ProjectSelector key={project._id} project={project} show={showProjects} src={SERVER+project.baseImg} />
                })}
            </div>
            <Switch>
                <Route exact path="/:id" render={(props)=>{
                    const id = props.match.params.id as string;
                    const data = find(projects,["_id",id]);
                    if(data){
                        return <ProjectPage id={id} data={data} {...props}/>;
                    }else{
                        return <Redirect to="/"/>;
                    }
                    
                }}/>
                <Route exact path="/" render={()=><CornerButton
                        showProjects={showProjects}
                        onClick={()=>dispatch(toggleProjects())}>
                            <img src={eye} alt="show projects"/>
                    </CornerButton>}/>
                <Redirect to="/"/>
            </Switch>
        </Projects>
        <MenuWrapper ref={ref} className={showMenu?"show":""}>
            <Languages />
            <Menu data={data}/>
        </MenuWrapper>
    </Swipeable></BrowserRouter>
}

function mapStateToProps(state:Map<any,any>):ReduxProps {
    const page = state.get(PAGE);

    return {
        showMenu:page.menu,
        showProjects:page.projects,
    };
}
export const Page = connect(mapStateToProps)(PageBase);