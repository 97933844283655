import * as LanguageActions from "../actions/LanguageActions";

import { Action } from "../base/action";
import { registerReducer, State } from "../base/reducer";
import { LANGUAGES } from "../lang";


export const LANGUAGE = "language";


function reducer(state:State, action:Action<any>) {
    const payload = action.payload as {language:keyof typeof LANGUAGES};

    switch (action.type) {
        case LanguageActions.languageChangeAction.type:
            return state.set(LANGUAGE, {lang:LANGUAGES[payload.language], id:payload.language});
    }

    return state;

}

// register the reducer
registerReducer(reducer, [
    LanguageActions.languageChangeAction.type,
]);
