import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { SERVER } from "../settings/connection";
import { ServerData } from "../types/data.type";
import bg from "../assets/media_bcg.png";

const PubWrapper = styled.div`
    width:100%;
    min-height:100%;
    padding:15px;
    display:block;
`;

const Pub = styled.a`
    position:relative;
    display:block;
    width:130px;
    height:183px;
    background:url(${(props:{uri?:string})=>props.uri ? SERVER+props.uri : bg});
    background-size:cover;
    float:left;
    margin:10px;
    box-shadow:0 0 15px 0 rgba(0,0,0,.25);
    cursor:pointer;
    overflow:hidden;
    & span {
        position:absolute;
        bottom:0;
        left:0;
        width:100%;
        background-color:#007BA5;
        opacity:0.7;
        color:white;
        min-height:50px;
        padding:5px;
        transform:translate(0,100%);
        transition: transform .2s;
    }
    &:hover span {
        transform:translate(0,0); 
    }
    @media (max-width:480px) {
        & span {
            transform:translate(0,0);
        }
    }
`;

type Props = {
    publication:ServerData["publications"][0];
    locale:"cs"|"en"|"sk";
}
const Publication:FunctionComponent<Props> = ({publication,locale})=>{
    return <Pub href={publication.src || "#"} target={publication.src ? "_blank" : undefined} uri={publication.img}>
        <span>
            {publication.name[locale]}
        </span>
    </Pub>;
}

type Props2 = {
    publications:ServerData["publications"];
    locale:"cs"|"en"|"sk";
}

export const Publications:FunctionComponent<Props2> = ({publications,locale})=>{
    return <PubWrapper>
        {publications.map((publication)=><Publication key={publication._id} locale={locale} publication={publication} />)}
    </PubWrapper>
}