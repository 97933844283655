export type Action<T extends {[key:string]:any}> = {payload:T, type:string};

interface ActionFunction<A extends any[], T extends {[key:string]:any}> {
    (...args:A):Action<T>;
    readonly type:string;
}

/**
 * Returns an action creation function, which has it's type added as static
 * property.
 */
export function createAction<A extends any[], T extends {[key:string]:any}>(type:string, payload:(...args:A)=>T):ActionFunction<A, T> {
    // prepare wrapper function, which injects action type to the result of
    // payload function call
    const wrapper = function(...args:A) {
        const result = {
            payload : payload(...args),
            type    : type,
        };

        return result;
    } as ActionFunction<A, T>;

    // add the action type as static properties to the wrapper function
    Object.defineProperty(wrapper, "type", {
        enumerable   : false,
        configurable : false,
        writable     : false,
        value        : type,
    });

    // return the wrapper function
    return wrapper;
}
