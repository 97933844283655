import { Map } from "immutable";
import React, { FunctionComponent } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import { languageChangeAction } from "../actions/LanguageActions";
import { toggleMenu } from "../actions/PageActions";
import { LANGUAGE } from "../reducers/LanguageReducers";

const LangWrapper = styled.div`
    width:100%;
    height:50px;
    display:flex;
    align-items:stretch;
    border-bottom:1px solid #eee;
`;

const Lang = styled.div`
    color:#007BA5;
    flex-grow:1;
    text-align:center;
    display:flex;
    align-items:center;
    justify-content:center;
    border-right:1px solid #eee;
    cursor:pointer;
    &:hover, &.active {
        background-color:#007BA5;
        color:white;
    }
`;
type Props = {
    locale:"cs"|"sk"|"en";
}
const LanguagesBase:FunctionComponent<Props> = ({locale})=>{
    const dispatch = useDispatch();
    return <LangWrapper>
        <Lang onClick={()=>dispatch(toggleMenu())}>x</Lang>
        <Lang className={locale === "cs"?"active":""} onClick={()=>dispatch(languageChangeAction("cs"))}>cs</Lang>
        <Lang className={locale === "sk"?"active":""} onClick={()=>dispatch(languageChangeAction("sk"))}>sk</Lang>
        <Lang className={locale === "en"?"active":""} onClick={()=>dispatch(languageChangeAction("en"))}>en</Lang>
    </LangWrapper>
}

const mapStateToProps = (state:Map<any,any>):Props=>({
    locale:state.get(LANGUAGE).id
});

export const Languages = connect(mapStateToProps)(LanguagesBase);

