import Immutable from "immutable";

import { consoleDevLog } from "../utils/consoleDevLog";
import { Action } from "./action";

export type State = Immutable.Map<any, any>;

type RegisteredReducer = {
    executor : (state:State, action:Action<any>)=>any;
    actions  : any[];
}

const registeredReducers:RegisteredReducer[] = [];

/**
 * Registers new reducer function for application state
 */
export function registerReducer(executor:RegisteredReducer["executor"], actions:RegisteredReducer["actions"]):void {
    registeredReducers.push({
        executor : executor,
        actions  : actions,
    });
}

const reduxDevLog = consoleDevLog({}, {
    name:{
        type : "string",
        data : "redux",
    }
});

/**
 * Application root reducer function, used by Redux state storage when an
 * action is dispatched
 */
export function rootReducer(state:State = Immutable.Map(), action:Parameters<RegisteredReducer["executor"]>[1]):State {
    // pass the state to reducers which can handle specified action
    registeredReducers.forEach((reducer) => {
        if (reducer.actions.indexOf(action.type) >= 0) {
            state = reducer.executor(state, action);
        }
    });

    reduxDevLog(action.type.toString(), {
        action : action,
        state  : state.toJS(),
    });

    return state;
}
