import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { LANGUAGES } from "../lang";
import { LANGUAGE } from "../reducers/LanguageReducers";
import { SERVER } from "../settings/connection";
import { ServerData } from "../types/data.type";
import filter from "lodash/filter";
import close from "../assets/close.svg";
import { CornerButton } from "./CornerButton";
import { RouteChildrenProps } from "react-router-dom";
import { openMap } from "../utils/openMaps";

const ProjectWrapper = styled.div`
    position:absolute;
    top:0;
    left:0;
    display:flex;
    justify-content:center;
    width:100vw;
    min-height:100%;
    background:rgba(255,255,255,.5);
    z-index:2;
`;

const Project = styled.div`
    display:flex;
    position:relative;
    width:70%;
    background:white;
    box-shadow:0 0 15px 0 rgba(0,0,0,.15);
    padding:2em 4em;
    box-sizing:border-box;
    max-height:10000px;
    @media (max-width:1050px) {
        width:100%;
        padding:2em 0em;
        flex-direction:column;
        & h1,& span {
            padding:0 25px;
            font-size:20px;
        }
        & span {
            margin-bottom:15px;
        }
    }
    & .root {

        & div {
            display:block;
            width:100%;
        }
        & img {
            width:inherit;
            padding:5px 0;
        }
        & h1 {
            font-weight:100;
            padding-bottom:0;
            margin-bottom:0.1em;
            text-transform: uppercase;
        }
        & span {
            display:block;
            font-size:.9em;
            color:#007BA5;
            text-transform: uppercase;
            padding-bottom: 1em;
            cursor:pointer;
        }
        @media (min-width:1050px) {
            flex-basis:70%;
        }
    }
    & .desc {
        flex-grow:1;
        flex-shrink:1;
        padding:5.2em 0 0 2em;
        p {
            margin:0;
            padding:0;
            line-height:1.5em;
            font-size:.7em;
            &:nth-child(odd) {
                font-weight:bold;
                padding-top:1em;
                text-transform: uppercase;
                font-size:.7em;
            }
        }
        @media (max-width:1050px) {
            padding:2em 0 5.2em 0;
            & p {
                text-align:center;
            }
        }
    }
`;

type ReduxProps = {
    locale:keyof typeof LANGUAGES;
}


type Props = {
    id:string;
    data:ServerData["projects"][0];
} & ReduxProps & RouteChildrenProps;

const ProjectPageBase:FunctionComponent<Props> = ({data,locale,history})=>{
    const descriptionField = filter(data.description[locale].split("\n"),(item)=>item !=="");
    descriptionField.push(
        LANGUAGES[locale].copyright_name+":",
        LANGUAGES[locale].copyright
    );
    return <ProjectWrapper>
        <CornerButton onClick={()=>history.push("/")}>
            <img alt="close" src={close}/>
        </CornerButton>
        <Project>
            <div className="root">
                <h1>{data.name[locale]}</h1>
                <span onClick={()=>openMap(data.gps)}>{data.locationName[locale]}</span>
                <div>
                    {data.imgs.map((img)=><img alt={data.name[locale]} key={img} src={SERVER+img}/>)}
                </div>
            </div>
            <div className="desc">
                {descriptionField.map((field)=><p key={field}>{field}</p>)}
            </div>
        </Project>
    </ProjectWrapper>
}

const mapStateToProps = (state:Map<any,any>):ReduxProps=>{
    const lang = state.get(LANGUAGE);
    return {
        locale: lang.id
    }
};

export const ProjectPage = connect(mapStateToProps)(ProjectPageBase);