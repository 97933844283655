import { Map } from "immutable";
import React, { FunctionComponent, useState } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import { toggleMenu, toggleProjects } from "../actions/PageActions";
import { LANGUAGES } from "../lang";
import { LANGUAGE } from "../reducers/LanguageReducers";
import { ServerData } from "../types/data.type";
import { Contact } from "./Contact";
import { Publications } from "./Publications";
const MenuWrapper = styled.div`
    width:100%;
    min-height:calc(100% - 50px);
    display:flex;
    flex-direction:column;
`;

const MenuItemBase = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    & .button {
        display:flex;
        justify-content:space-between;
        align-items:center;
        padding:10px 25px;
        color:#007BA5;
        font-weight:100;
        border-bottom:1px solid #eee;
        cursor:pointer;
        height:65px;
        &:hover {
            background-color:#eee;
        }
        & span {
            font-size:1.2em;
            text-transform: lowercase;
            letter-spacing:3px;
        }
        & b {
            font-size:1.5em;
            transform-origin:50% 55%;
        }
    }
    &.collapsed b {
        transform:rotate(270deg);
    }
    &.expanded {
        flex-grow:1;
        & b {
            transform:rotate(90deg);
        }
    }
    & .content {
        padding-top:15px;
    }
`;

type Props = {
    locale:"cs" | "sk" | "en";
    data:ServerData;
}

type MenuItemProps = {
    label:string;
    expanded?:boolean;
    bottom?:boolean;
    onExpanded?:(expanded?:boolean)=>any;
}

const MenuItem:FunctionComponent<React.ComponentProps<"div">&MenuItemProps> = ({label,children,bottom,expanded,onClick,onExpanded,...props})=>{
    const classes = !children ? "" : expanded ? "expanded" : "collapsed";
    return <MenuItemBase style={{position:"relative"}} className={classes}>
        <div className="button" onClick={(ev)=>{
            const e = !expanded;
            if(onExpanded && children){
                onExpanded(e);
            }
            if(onClick){
                onClick(ev);
            }
        }} {...props}><span>{label}</span><b>{">"}</b></div>
        {expanded && <div style={bottom ? {display:"flex",flexDirection:"column",justifyContent:"flex-end",flexGrow:1}:undefined} className="content">{children}</div>}
    </MenuItemBase>
}

const MenuBase:FunctionComponent<Props> = ({locale,data})=>{
    const dispatch = useDispatch();
    const [expanded,setExpanded] = useState<"contact"|"pubs"|null>("contact");
    return <MenuWrapper>
        <MenuItem onClick={()=>{
            dispatch(toggleMenu());
            dispatch(toggleProjects(true));
        }} label={LANGUAGES[locale].projects}></MenuItem>
        <MenuItem onExpanded={(e?:boolean)=>e === true?setExpanded("pubs") : setExpanded(null)} expanded={expanded === "pubs"} label={LANGUAGES[locale].publications}>
            <Publications locale={locale} publications={data.publications}/>
        </MenuItem>
        <MenuItem onExpanded={(e?:boolean)=>e === true?setExpanded("contact") : setExpanded(null)} bottom expanded={expanded === "contact"} label={LANGUAGES[locale].contact}>
            <Contact contacts={data.contact}></Contact>
        </MenuItem>
    </MenuWrapper>
}

const mapStateToProps = (state:Map<any,any>):Pick<Props,"locale"> =>({
    locale:state.get(LANGUAGE).id
});

export const Menu = connect(mapStateToProps)(MenuBase);