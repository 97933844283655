import * as PageActions from "../actions/PageActions";

import { Action } from "../base/action";
import { registerReducer, State } from "../base/reducer";


export const PAGE = "page";

type PageState = {
    menu:boolean;
    projects:boolean;
}

function reducer(state:State, action:Action<any>) {
    const payload = action.payload as PageState;
    const page = state.get(PAGE);
    const project = payload.projects !== undefined ? payload.projects : !page.projects;
    switch (action.type) {
        case PageActions.toggleMenu.type:
            return state.set(PAGE, {...page, menu:payload.menu !== undefined ? payload.menu : !page.menu});
        case PageActions.toggleProjects.type:
            return state.set(PAGE, {...page,projects:project});
    }

    return state;

}

// register the reducer
registerReducer(reducer, [
    PageActions.toggleMenu.type,
    PageActions.toggleProjects.type
]);