import Immutable from "immutable";

import { createStore } from "./base/store";
import { LANGUAGES } from "./lang";
import { LANGUAGE } from "./reducers/LanguageReducers";
import { PAGE } from "./reducers/PageReducers";
import { LOCALE } from "./utils/detectLanguage";

const defaultState = Immutable.Map({
    [LANGUAGE]:{
        lang:LANGUAGES[LOCALE],
        id:LOCALE
    },
    [PAGE]:{
        menu:false,
        projects:false
    }
});

export const store = createStore(defaultState);
