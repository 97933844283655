import objectPath from "object-path";

type DataType = {
    type:"path"|"string";
    data:string;
}
type InitialData = {
    name:DataType;
    typeAddition?:DataType;
}

type ConsoleDevLogMethod = (type:string, customProperties?:[string, any][]|{[key:string]:any})=>void;

function getValue(source:{[key:string]:any}, obj:DataType) {
    return obj.type === "path" ? objectPath.get(source, obj.data) : obj.data;
}

/**
 * Creates DevLog function which prints data in nice formatted way
 * @param source object or class from which data will be used
 * @param settings  initial settings
 */
export const consoleDevLog = <T extends {[key:string]:DataType}>(source:{[key:string]:any}, settings:InitialData & T):ConsoleDevLogMethod => {
    if (process.env.NODE_ENV !== "production") {
        const {name, typeAddition, ...custom} = settings;

        const method:ConsoleDevLogMethod = (type, customProperties) => {
            const nameValue = getValue(source, name);
            const additionValue = typeAddition ? getValue(source, typeAddition) : undefined;

            console.groupCollapsed(`${nameValue}(${type})${additionValue ? `(${additionValue})` : ""}`);

            Object.keys(custom).map((key) => console.log(key + ":", getValue(source, custom[key])));

            if (customProperties && Array.isArray(customProperties)) {
                customProperties.map((args) => console.log(...args));
            }
            else if (customProperties) {
                Object.keys(customProperties).map((key) => console.log(key + ":", customProperties[key]));
            }

            console.groupEnd();
        };

        return method;
    }
    else {
        return (() => null) as ConsoleDevLogMethod;
    }
};
