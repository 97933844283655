import React, { Component } from "react";
import styled from "styled-components";
import { ServerData } from "../types/data.type";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { withRouter } from 'react-router-dom';

type PixelWrapperProps = {
    contain?:true;
    size?:number;
    show?:boolean;
}
export const PixelWrapper = styled.div`
    position:relative;
    width:calc( 100% /6 );
    height:calc( 100vw /6 );
    float:left;
    overflow:hidden;
    user-select:none;
    cursor:pointer;
    &>* {
        width:100%;
        height:100%;
    }
    & canvas {
        pointer-events:none;
        position:absolute;
        top:0;
        left:0;
        opacity:1;
        transition:opacity .3s;
        image-rendering: pixelated;

        &.hide {
            opacity:0;
            transition:opacity .3s ease-in .5s;
        }
    }
    & img {
        object-fit:${({contain}:PixelWrapperProps)=>contain?"contain":"cover"};
        object-position:center;
        transition:transform .3s 1s ease-out, box-shadow .1s ease-in;
        ${({show}:PixelWrapperProps)=>{
            if(show){
                return `
                    transform:scale(.9);
                    &:hover {
                        box-shadow:0 0 25px 0 rgba(0,0,0,.25);
                    }
                `
            }
        }}
        box-shadow:0 0 15px 0 rgba(0,0,0,.15);
    }
    @media (max-width:1050px) {
        width:calc( 100% /4 );
        height:calc( 100vw /4 );
    }
    @media (max-width:480px) {
        width:calc( 100% /2 );
        height:calc( 100vw /2 );
    }
`;

type Props = {
    src: string;
    show?: boolean;
    project:ServerData["projects"][0];
} & RouteComponentProps

type State = {
    loaded:boolean;
    redirect:boolean;
}

class ProjectSelectorBase extends Component<Props,State> {
    
    canvasRef:React.RefObject<HTMLCanvasElement> = React.createRef();

    image = new Image();

    pixelation = 5;

    max = 50;

    v = this.pixelation;

    play = false;

    next:boolean | null | undefined = null;

    touched = false;

    clickCounter = 0;

    state:State = {
        loaded : false,
        redirect: false
    }

    private canvas?:HTMLCanvasElement;

    private ctx?:CanvasRenderingContext2D | null;

    private direction?:boolean;

    componentDidMount() {
        const { src } = this.props;
        if (src) {
            this.image.onload = ()=>{
                this.setState({ loaded: true });
            };
            this.image.src = src;
        }
    }
    private getCanvas(withoutGeometry:true):HTMLCanvasElement;
    private getCanvas(withoutGeometry?:boolean) {
        if(this.canvasRef.current){
            return withoutGeometry ? this.canvasRef.current : this.canvasRef.current.getContext("2d");
        } else {
            throw new Error("Canvas does not exits");
        }
        
    }

    drawImage = () => {
        this.canvas = this.getCanvas(true);
        this.ctx = this.canvas.getContext("2d");
        if(this.ctx){
            //@ts-ignore
            this.ctx.mozImageSmoothingEnabled = false;
            //@ts-ignore
            this.ctx.webkitImageSmoothingEnabled = false;
            this.ctx.imageSmoothingEnabled = false;
            this.pixelate();
        }
        
    }

    componentDidUpdate(prev:Props){
        if(this.props.show){
            this.startAnimation();
        }else{
            this.startAnimation(true);
        }
    }

    pixelate() {
        var size = this.v,

            /// cache scaled width and height
            w = size,
            h = size;

        if(this.ctx && this.canvas){
            /// draw original image to the scaled size
            this.ctx.drawImage(this.image, 0, 0, w, h);

            /// then draw that scaled image thumb back to fill canvas
            /// As smoothing is off the result will be pixelated
            this.ctx.drawImage(this.canvas, 0, 0, w, h, 0, 0, this.canvas.width, this.canvas.height);
        }
    }

    startAnimation(out?:boolean | null) {
        if(out){
            this.clickCounter = 0;
            this.touched = false;
        }
        if(this.props.show){
            this.play = false;
            this.next = false;
        }
        if ((!this.play && this.canvas)) {
            this.direction = !out;
            this.play = true;
            this.canvas.setAttribute("class", this.direction ? "hide" : "");
            this.anim();
        }
        else if (!out && this.canvas) {
            this.direction = !out;
            this.canvas.setAttribute("class", "hide");
        }
        else {
            this.next = out;
        }

    }

    getNearest(n:number, min:number, max:number) {
        if (n < min) {
            return min;
        }
        else if (n > max) {
            return max;
        }
        else {
            return n;
        }
    }

    anim = () => {
        const {show} = this.props;
        /// if at min or max reverse delta
        if (this.direction) {
            this.v += 1;
        }
        else {
            this.v -= 1;
        }

        if (this.v < this.pixelation || this.v > this.max) {
            this.play = false;
            this.v = this.getNearest(this.v, this.pixelation, this.max);
            if (this.next !== null || show) {
                setTimeout(()=>{
                    const direction = this.next;
                    this.next = null;
                    if(!show){
                        this.startAnimation(direction);
                    }
                }, 500);
            }
            return;
        }

        /// pixelate image with current value
        this.pixelate();

        /// loop
        if (this.play === true) {
            requestAnimationFrame(this.anim);
        }
    }

    render() {
        const { src, show, project, history} = this.props;
        const { loaded, redirect} = this.state;
        if (loaded) {
            this.drawImage();
        }
        return <PixelWrapper
                onTouchStart={()=>{
                    this.touched = true;
                }}
                onClick={()=>{
                    if((this.touched && this.clickCounter === 1) || !this.touched || show){
                        this.clickCounter = 0;
                        this.touched = false;
                        history.push("/"+project._id);
                    }else{
                        this.startAnimation();
                        this.clickCounter ++;
                    }
                }}
                onMouseLeave={()=>loaded && show !== true ? this.startAnimation(true) : null}
                onMouseEnter={()=>loaded && show !== true ? this.startAnimation() : null}
                show={show}
            >
                {redirect && <Redirect to={"/"+project._id} />}
                <img src={src} alt="Test"/>
                <canvas
                    width={600} height={600}
                    ref={this.canvasRef} />
            </PixelWrapper>
    }
}

export const ProjectSelector = withRouter(ProjectSelectorBase);
