import { Map } from "immutable";
import React, { FunctionComponent, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import sk from "../assets/AK.gif";
import cs from "../assets/SB.gif";
import { LANGUAGE } from "../reducers/LanguageReducers";
import { ServerData } from "../types/data.type";
import find from "lodash/find";

const ContactWrapper = styled.address`
    width:100%;
    font-style:normal;
    line-height:1.5em;
    & img {
        width:100%;
        cursor:pointer;
        margin-top:15px;
    }
    & > * {
        color:#007BA5;
        display:block;
    }
    &:not(img) {
        padding:0px 20px;
        text-align:center;
    }
    & a {
        text-decoration:none;
    }
`;

type Props = {
    locale: "cs"|"sk"|"en";
    contacts:ServerData["contact"]
}

const ContactBase:FunctionComponent<Props> = ({locale,contacts})=>{
    const [type,setType] = useState<"cs" | "sk" | null>(null);
    const lang:"cs" | "sk" = type || (locale === "en" ? "cs" : locale);
    const contact = find(contacts,["lang",lang]) as ServerData["contact"][0];
    return <ContactWrapper>
        <span>{contact.name}</span>
        <span>{contact.address}</span>
        <a href={"mailto:"+contact.email}>{contact.email}</a>
        <a href={"tel:"+contact.phone}>{contact.phone}</a>
        <img onClick={()=>setType(lang === "cs" ? "sk" : "cs")} src={lang === "cs" ? cs : sk} alt="Contact"/>
    </ContactWrapper>
}

const mapStateToProps = (state:Map<any,any>)=>{
    return {
        locale:state.get(LANGUAGE).id
    }
};

export const Contact = connect(mapStateToProps)(ContactBase);
