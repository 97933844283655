import React, { FunctionComponent, useLayoutEffect, useState } from 'react';
import { axios } from './base/axios';
import { Page } from './Components/Page';
import { ServerData } from './types/data.type';

export const App:FunctionComponent = ()=>{
    const [data,setData] = useState<ServerData | undefined>(undefined);
    useLayoutEffect(()=>{
        if(!data){
            axios.get("/").then((data)=>{
                if(data.data){
                    setData(data.data);
                }
            })
        }
    })
    return <div style={{position:"absolute",width:"100%",minHeight:"100%",overflowX:"hidden"}}>
        {data && <Page data={data}/>}
    </div>;

}
